import { Button, Dialog, Heading, Modal, ModalOverlay } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryNavLinkButton } from '../primary-nav-link-button';
import { useRootLoaderData } from '~/root';

type EnableRunnerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function EnableRunnerModal({ isOpen, onClose }: EnableRunnerProps) {
  const { currentPlan } = useRootLoaderData();

  return (
    <ModalOverlay
      isDismissable
      isOpen={isOpen}
      aria-label="modal"
      className={({ isEntering, isExiting }) =>
        twMerge(
          'fixed inset-0 z-10 flex min-h-full items-center justify-center overflow-y-auto bg-[#00000080] p-4 text-center',
          isEntering ? 'duration-300 ease-out animate-in fade-in' : '',
          isExiting ? 'duration-200 ease-in animate-out fade-out' : '',
        )
      }
    >
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={({ isEntering, isExiting }) =>
          twMerge(
            'w-full max-w-[600px] overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl',
            isEntering ? 'duration-300 ease-out animate-in zoom-in-95' : '',
            isExiting ? 'duration-200 ease-in animate-out zoom-out-95' : '',
          )
        }
      >
        <Dialog role="alertdialog" className="relative m-0 outline-none">
          {({ close }) => (
            <div className="flex h-full flex-col">
              <div className="flex items-center justify-between px-6 pb-2 pt-4">
                <Heading slot="title" className="my-0 text-[16px] font-[500] leading-6 tracking-[-0.25px] text-black">
                  Enable AI Runner
                </Heading>

                <Button
                  className="p-2 text-slate-500 hover:text-slate-600"
                  onPress={() => {
                    onClose();
                    close();
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} className="h-4 w-4 cursor-pointer text-[#00000066]" />
                </Button>
              </div>
              <div className="flex-1 overflow-y-auto p-6 pt-3">
                <div className="text-slate-500">
                  <div className="flex flex-col">
                    <p className="m-0 text-sm font-normal leading-[18px] tracking-[-0.25px] text-black/85">
                      AI Runner has currently been disabled for your organization.{' '}
                      {currentPlan?.type === 'enterprise-member' ? 'Contact the owners to enable it.' : ''}
                    </p>
                  </div>
                  {currentPlan?.type === 'enterprise' && (
                    <PrimaryNavLinkButton to="/app/enterprise/ai" className="mt-4 w-[180px]">
                      Enable
                    </PrimaryNavLinkButton>
                  )}
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
