import { Button, Dialog, Heading, ListBox, ListBoxItem, Modal, ModalOverlay, Text } from 'react-aria-components';
import type { Guardrails } from './types';
import { twMerge } from 'tailwind-merge';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PrimaryButton } from '../primary-button';
import { useState } from 'react';

const GuardrailsTypes = [
  {
    label: 'Bias and Fairness Checks',
    value: 'biasAndFairnessChecks',
    description: 'Ensuring Al models avoid harmful biases, promoting fairness across demographics.',
  },
  {
    label: 'Explainability',
    value: 'explainability',
    description: 'Providing transparency in Al decisions by making the reasoning process understandable.',
  },
  {
    label: 'Privacy Protections',
    value: 'privacyProtections',
    description: 'Safeguarding personal data through encryption, anonymization, and limiting data use.',
  },
  {
    label: 'Content Moderation',
    value: 'contentModeration',
    description:
      'Preventing harmful or inappropriate content generation, such as hate speech, misinformation, or explicit material.',
  },
  {
    label: 'Ethical Al Frameworks',
    value: 'ethicalAiFrameworks',
    description:
      'Following ethical guidelines that emphasize accountability, safety, and responsibility in Al deployment.',
  },
  {
    label: 'Security Protections',
    value: 'securityProtections',
    description:
      'Preventing malicious use and vulnerabilities in Al models (e.g., prompt injection, prompt jailbreaking, adversarial attacks and more).',
  },
  {
    label: 'Regulatory Compliance',
    value: 'regulatoryCompliance',
    description: 'Adhering to local and international laws, such as GDPR and Al-specific regulations.',
  },
  {
    label: 'Short responses',
    value: 'shortResponses',
    description: 'Limit responses to maximum one sentence to improve performance and going straight to the point.',
  },
  {
    label: 'Technology Concealment',
    value: 'technologyConcealment',
    description: 'Ensure the Al never reveals the underlying technology used to process the GenAl requests.',
  },
];

type GuardrailsModalProps = {
  guardrails: Guardrails[];
  onSelect: (guardrails: Guardrails[]) => void;
};

export function GuardrailsModal({ guardrails, onSelect }: GuardrailsModalProps) {
  const [currentGuardrails, setCurrentGuardrails] = useState<Guardrails[]>(guardrails);

  return (
    <ModalOverlay
      isDismissable
      aria-label="modal"
      className={({ isEntering, isExiting }) =>
        twMerge(
          'fixed inset-0 z-10 flex min-h-full items-center justify-center overflow-y-auto bg-[#00000080] p-4 text-center',
          isEntering ? 'duration-300 ease-out animate-in fade-in' : '',
          isExiting ? 'duration-200 ease-in animate-out fade-out' : '',
        )
      }
    >
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={({ isEntering, isExiting }) =>
          twMerge(
            'w-full max-w-[1000px] overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl',
            isEntering ? 'duration-300 ease-out animate-in zoom-in-95' : '',
            isExiting ? 'duration-200 ease-in animate-out zoom-out-95' : '',
          )
        }
      >
        <Dialog role="alertdialog" className="relative m-0 outline-none">
          {({ close }) => (
            <div className="flex h-full flex-col">
              <div className="flex items-center justify-between px-6 pb-2 pt-4">
                <Heading slot="title" className="my-0 text-[16px] font-[500] leading-6 tracking-[-0.25px] text-black">
                  Update guardrails
                </Heading>

                <Button className="p-2 text-slate-500 hover:text-slate-600" onPress={close}>
                  <FontAwesomeIcon icon={faXmark} className="h-4 w-4 cursor-pointer text-[#00000066]" />
                </Button>
              </div>
              <div className="flex-1 overflow-y-auto p-6 pt-3">
                <div className="text-slate-500">
                  <div className="flex flex-col">
                    <p className="m-0 text-sm font-normal leading-[18px] tracking-[-0.25px] text-black/85">
                      Determine the guardrails and rules you would like the Al to follow for each request.
                    </p>
                  </div>
                  <ListBox
                    items={GuardrailsTypes}
                    className="mt-3 grid grid-cols-1 gap-4 p-2 sm:grid-cols-2 md:grid-cols-3"
                    selectionMode="multiple"
                    selectedKeys={currentGuardrails}
                    onSelectionChange={(keys) => {
                      setCurrentGuardrails(Array.from(keys) as Guardrails[]);
                    }}
                  >
                    {({ label, value, description }) => (
                      <ListBoxItem
                        key={value}
                        id={value}
                        textValue={label}
                        aria-label={label}
                        className="group flex min-h-[100px] cursor-pointer select-none flex-col items-start gap-2 rounded border border-black/15 px-2 py-2 text-gray-900 outline-none hover:bg-gray-100 data-[selected]:rounded data-[selected]:bg-[#F9F7FF] data-[selected]:hover:bg-[#F9F7FF]"
                      >
                        {({ isSelected }) => (
                          <div className="flex w-full flex-col gap-1">
                            <div className="flex w-full items-center justify-between gap-2">
                              <div className="flex items-center gap-2">
                                <Text
                                  slot="label"
                                  className="group-selected:font-medium flex flex-1 items-center gap-2 truncate text-sm font-medium capitalize text-black/85 "
                                >
                                  {label}
                                </Text>
                              </div>
                              {isSelected && <FontAwesomeIcon icon={faCheck} className="h-4 w-4 text-[#4000BF]" />}
                            </div>
                            <Text slot="description" className="text-[13px] font-medium text-black/50">
                              {description}
                            </Text>
                          </div>
                        )}
                      </ListBoxItem>
                    )}
                  </ListBox>
                  <div className="flex w-full items-center justify-end">
                    <PrimaryButton
                      className="mt-6 h-[34px] w-[100px]"
                      onPress={() => {
                        onSelect(currentGuardrails);
                        close();
                      }}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
