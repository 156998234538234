import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faChevronUp, faClock } from '@fortawesome/free-solid-svg-icons';

import type { AffinityLevel, AIRunner, CacheUnit, Guardrails, LLMNames, LLMType } from './types';
import {
  AffinityLoose,
  AffinityMedium,
  AffinityNoCache,
  AffinityStrong,
  AnthropicAILogo,
  AnthropicAiLogoIcon,
  CohereAILogo,
  CohereAILogoIcon,
  GuardrailsIcon,
  MistralAILogo,
  MistralAiLogoIcon,
  NoGuardrailsIcon,
  OpenAILogo,
  OpenAiLogoIcon,
} from './icons';
import {
  Button,
  DialogTrigger,
  Group,
  Input,
  Menu,
  MenuItem,
  MenuTrigger,
  NumberField,
  Popover,
  Separator,
  // TextField,
  Tooltip,
  TooltipTrigger,
} from 'react-aria-components';
// import { twMerge } from 'tailwind-merge';

// import nistIcon from '~/assets/ai/nist.png';
// import soc2Icon from '~/assets/ai/soc2.png';
// import starIcon from '~/assets/ai/star.png';

import { ProviderModal } from './provider-modal';
import { AffinityLevelModal } from './affinity-modal';
import { useRootLoaderData } from '~/root';
import { createGuardrailsObject, formatNumberWithCommas, getGuardrailsCount } from './utils';
import { EnableRunnerModal } from './enable-runner-modal';
import { GuardrailsModal } from './guardrails-modal';

const LLMs: LLMType[] = [
  { label: 'Open AI', value: 'openai', icon: <OpenAiLogoIcon /> },
  { label: 'Anthropic', value: 'anthropic', icon: <AnthropicAiLogoIcon /> },
  { label: 'Mistral', value: 'mistral', icon: <MistralAiLogoIcon /> },
  { label: 'Cohere', value: 'cohere', icon: <CohereAILogoIcon /> },
];

const CacheUnits = [
  { label: 'Seconds', value: 'seconds' },
  { label: 'Minutes', value: 'minutes' },
  { label: 'Days', value: 'days' },
];

type SettingsBarProps = {
  settings: AIRunner;
  isRunnerEnabled: boolean;
  onChangeSettings: (settings: AIRunner) => void;
  onUpdateApiKey: (apiKey?: string) => void;
  isNew?: boolean;
};

export function SettingsBar({ settings, isNew, isRunnerEnabled, onChangeSettings, onUpdateApiKey }: SettingsBarProps) {
  const { currentPlan } = useRootLoaderData();

  const [showEnableRunnerModal, setShowEnableRunnerModal] = useState(false);
  const [isCacheTTLOpen, setIsCacheTTLOpen] = useState(false);

  // const [apiKey, setApiKey] = useState<string | undefined>(
  //   settings.authentication ? '***** ***** ***** ***** *****' : undefined,
  // );

  const { provider, affinityLevel } = settings;

  const selectedLLM = LLMs.find((l) => l.value === provider);

  // const hideAPIKey = settings.authentication && apiKey !== '';

  const [currentCacheTtl, setCurrentCacheTtl] = useState(settings.cacheTtl);
  const [currentCacheTtlUnit, setCurrentCacheTtlUnit] = useState<CacheUnit>(settings.cacheTtlUnit);

  if ((currentPlan?.type === 'enterprise' || currentPlan?.type === 'enterprise-member') && !isRunnerEnabled) {
    return (
      <div className="relative flex w-full flex-1 flex-col items-center gap-3 rounded-md bg-[#151544] transition-all duration-300">
        <div className="relative flex w-full flex-col justify-between gap-4 p-2">
          <div className="flex flex-1 items-center gap-1 border-b border-black/30 sm:gap-2">
            <Button
              className="focus-visible:ring-ring flex h-8 w-fit flex-1 shrink-0 select-none items-center justify-center gap-2 whitespace-nowrap rounded-md bg-[#151544] px-2 py-2 text-sm font-medium text-white transition-colors hover:bg-[#151544] hover:bg-opacity-70 focus-visible:bg-[#151544] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50 sm:w-32 sm:px-3"
              type="button"
              onPress={() => setShowEnableRunnerModal(true)}
            >
              <div className="flex items-center gap-2">
                {provider === 'openai' ? (
                  <OpenAILogo />
                ) : provider === 'anthropic' ? (
                  <AnthropicAILogo />
                ) : provider === 'mistral' ? (
                  <MistralAILogo />
                ) : (
                  <CohereAILogo />
                )}
                <div className="text-[13px] font-normal">{selectedLLM?.label}</div>
                <FontAwesomeIcon icon={faChevronDown} className="h-2.5 w-2.5 text-white/70" />
              </div>
            </Button>

            <Separator className="h-[20px] w-[1px] border-0 bg-white/20" />
            <Button
              className="focus-visible:ring-ring flex h-8 w-fit flex-1 shrink-0 select-none items-center justify-center gap-2 whitespace-nowrap rounded-md bg-[#151544] px-2 py-2 text-sm font-medium text-white transition-colors hover:bg-[#151544] hover:bg-opacity-70 focus-visible:bg-[#151544] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50 sm:w-44 sm:px-3"
              type="button"
              onPress={() => setShowEnableRunnerModal(true)}
            >
              <div className="flex items-center gap-2">
                {affinityLevel === 'none' ? (
                  <AffinityNoCache />
                ) : affinityLevel === 'loose' ? (
                  <AffinityLoose />
                ) : affinityLevel === 'medium' ? (
                  <AffinityMedium />
                ) : (
                  <AffinityStrong />
                )}
                <div className="text-[13px] font-normal">
                  {affinityLevel === 'none'
                    ? 'No cache'
                    : affinityLevel === 'loose'
                    ? 'Loose Similarity'
                    : affinityLevel === 'medium'
                    ? 'Medium Similarity'
                    : 'Strong Similarity'}
                </div>
                <FontAwesomeIcon icon={faChevronDown} className="h-2.5 w-2.5 text-white" />
              </div>
            </Button>
          </div>
          <div className="flex w-full items-center justify-center">
            <div className="flex w-full max-w-[90%] items-center justify-between gap-2 sm:max-w-[500px]">
              {/* <Button
                className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] focus-visible:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                type="button"
                onPress={() => setShowEnableRunnerModal(true)}
              >
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={hideAPIKey ? 'lock' : 'lock-open'} className="h-3 w-3" />
                  <div className="hidden text-[13px] font-medium sm:block">
                    {hideAPIKey ? 'Authenticated' : 'Client Auth'}
                  </div>
                  <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                </div>
              </Button> */}

              {settings.affinityLevel === 'none' && (
                <TooltipTrigger delay={0}>
                  <Button
                    className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white/30 focus-visible:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 sm:min-w-[100px]"
                    type="button"
                    onPress={() => setShowEnableRunnerModal(true)}
                  >
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
                      <div className="hidden text-[13px] font-medium sm:block">
                        {`${settings.cacheTtl}${
                          settings.cacheTtlUnit === 'seconds' ? 's' : settings.cacheTtlUnit === 'minutes' ? 'm' : 'd'
                        }`}{' '}
                        Cache TTL
                      </div>
                      <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" />
                    </div>
                  </Button>
                  <Tooltip
                    offset={isNew ? 17 : 11}
                    placement="bottom"
                    className="flex flex-col overflow-y-auto rounded-md bg-[#151544] px-3 py-2 text-[14px] text-sm text-white/60 shadow-[3.0px_3.0px_5.0px_rgba(0,0,0,0.38)] focus:outline-none"
                  >
                    Enable cache to change TTL
                  </Tooltip>
                </TooltipTrigger>
              )}
              {settings.affinityLevel !== 'none' && (
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] hover:text-white/80 focus-visible:bg-[#1D1D5E] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                  type="button"
                  onPress={() => setShowEnableRunnerModal(true)}
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
                    <div className="hidden text-[13px] font-medium sm:block">
                      {`${settings.cacheTtl}${
                        settings.cacheTtlUnit === 'seconds' ? 's' : settings.cacheTtlUnit === 'minutes' ? 'm' : 'd'
                      }`}{' '}
                      Cache TTL
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" />
                  </div>
                </Button>
              )}
              <Button
                className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                type="button"
                onPress={() => setShowEnableRunnerModal(true)}
              >
                <div className="flex items-center gap-2">
                  <GuardrailsIcon />
                  <div className="hidden text-[13px] font-medium sm:block">No guardrails</div>
                  <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" />
                </div>
              </Button>
            </div>
          </div>
        </div>
        <EnableRunnerModal isOpen={showEnableRunnerModal} onClose={() => setShowEnableRunnerModal(false)} />
      </div>
    );
  }

  const {
    biasAndFairnessChecks,
    explainability,
    privacyProtections,
    contentModeration,
    ethicalAiFrameworks,
    securityProtections,
    regulatoryCompliance,
    shortResponses,
    technologyConcealment,
  } = settings;

  const guardrailsCount = getGuardrailsCount({
    biasAndFairnessChecks,
    explainability,
    privacyProtections,
    contentModeration,
    ethicalAiFrameworks,
    securityProtections,
    regulatoryCompliance,
    shortResponses,
    technologyConcealment,
  });

  const guardrails = {
    biasAndFairnessChecks,
    explainability,
    privacyProtections,
    contentModeration,
    ethicalAiFrameworks,
    securityProtections,
    regulatoryCompliance,
    shortResponses,
    technologyConcealment,
  };

  return (
    <div className="relative flex w-full flex-1 flex-col items-center gap-3 rounded-md bg-[#151544] transition-all duration-300">
      <div className="relative flex w-full flex-col justify-between gap-4 p-2">
        <div className="flex flex-1 items-center gap-1 border-b border-black/30 sm:gap-2">
          <DialogTrigger>
            <Button
              className="focus-visible:ring-ring flex h-8 w-fit flex-1 shrink-0 select-none items-center justify-center gap-2 whitespace-nowrap rounded-md bg-[#151544] px-2 py-2 text-sm font-medium text-white transition-colors hover:bg-[#151544] hover:bg-opacity-70 focus-visible:bg-[#151544] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50 sm:w-32 sm:px-3"
              type="button"
            >
              <div className="flex items-center gap-2">
                {provider === 'openai' ? (
                  <OpenAILogo />
                ) : provider === 'anthropic' ? (
                  <AnthropicAILogo />
                ) : provider === 'mistral' ? (
                  <MistralAILogo />
                ) : (
                  <CohereAILogo />
                )}
                <div className="text-[13px] font-normal">{selectedLLM?.label}</div>
                <FontAwesomeIcon icon={faChevronDown} className="h-2.5 w-2.5 text-white/70" />
              </div>
            </Button>
            <ProviderModal
              provider={provider as LLMNames}
              onSelect={(provider: LLMNames) => {
                onChangeSettings({
                  ...settings,
                  provider,
                });
              }}
            />
          </DialogTrigger>
          <Separator className="h-[20px] w-[1px] border-0 bg-white/20" />
          <DialogTrigger>
            <Button
              className="focus-visible:ring-ring flex h-8 w-fit flex-1 shrink-0 select-none items-center justify-center gap-2 whitespace-nowrap rounded-md bg-[#151544] px-2 py-2 text-sm font-medium text-white transition-colors hover:bg-[#151544] hover:bg-opacity-70 focus-visible:bg-[#151544] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50 sm:w-44 sm:px-3"
              type="button"
            >
              <div className="flex items-center gap-2">
                {affinityLevel === 'none' ? (
                  <AffinityNoCache />
                ) : affinityLevel === 'loose' ? (
                  <AffinityLoose />
                ) : affinityLevel === 'medium' ? (
                  <AffinityMedium />
                ) : (
                  <AffinityStrong />
                )}
                <div className="text-[13px] font-normal">
                  {affinityLevel === 'none'
                    ? 'No cache'
                    : affinityLevel === 'loose'
                    ? 'Loose Similarity'
                    : affinityLevel === 'medium'
                    ? 'Medium Similarity'
                    : 'Strong Similarity'}
                </div>
                <FontAwesomeIcon icon={faChevronDown} className="h-2.5 w-2.5 text-white" />
              </div>
            </Button>
            <AffinityLevelModal
              affinityLevel={affinityLevel}
              onSelect={(affinityLevel: AffinityLevel) => {
                onChangeSettings({
                  ...settings,
                  affinityLevel,
                });
              }}
            />
          </DialogTrigger>
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="flex w-full max-w-[90%] items-center justify-between gap-2 sm:max-w-[500px]">
            {/* <DialogTrigger>
              <Button
                className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] focus-visible:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                type="button"
              >
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={hideAPIKey ? 'lock' : 'lock-open'} className="h-3 w-3" />
                  <div className="hidden text-[13px] font-medium sm:block">
                    {hideAPIKey ? 'Authenticated' : 'Client Auth'}
                  </div>
                  <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                </div>
              </Button>
              <Popover
                placement="bottom end"
                offset={isNew ? 17 : 11}
                className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 flex w-80 origin-top-left flex-col gap-2.5 overflow-auto rounded-md bg-[#151544] p-2.5 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
              >
                <span className="text-[12px] font-medium text-white/60">
                  You can send your authentication in your code, or you can securely store it in Insomnia:
                </span>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between gap-2">
                    <TextField className="h-8 w-full rounded-md bg-[#1D1D5E] text-sm" aria-label="authentication input">
                      <Input
                        className={twMerge(
                          'h-8 w-full rounded-md border-none bg-[#1D1D5E] text-[12px] text-white focus:ring-0',
                          hideAPIKey && 'select-none text-white/40 blur-[2.5px]',
                        )}
                        placeholder="API Key"
                        value={apiKey}
                        disabled={hideAPIKey}
                        readOnly={hideAPIKey}
                        onChange={(e) => setApiKey(e.currentTarget.value)}
                        onCopy={(e) => {
                          if (hideAPIKey) {
                            e.preventDefault();

                            return;
                          }
                        }}
                        onSelect={(e) => {
                          if (hideAPIKey) {
                            e.preventDefault();
                            e.stopPropagation();
                            return;
                          }
                        }}
                      />
                    </TextField>
                    <Button
                      className="h-8 min-w-12 rounded-md border-none bg-[#4000BF] text-[12px] text-white focus-within:bottom-0 focus-within:outline-none hover:bg-opacity-80 focus:ring-0 focus-visible:ring-0 disabled:bg-white/30"
                      type="button"
                      isDisabled={!apiKey || apiKey === ''}
                      onPress={() => {
                        if (hideAPIKey) {
                          setApiKey('');

                          onChangeSettings({
                            ...settings,
                            authentication: false,
                          });

                          onUpdateApiKey('');

                          return;
                        }

                        onChangeSettings({
                          ...settings,
                          authentication: apiKey !== '',
                        });

                        onUpdateApiKey(apiKey);
                      }}
                    >
                      {hideAPIKey ? 'Reset' : 'Save'}
                    </Button>
                  </div>
                  <hr className="border-white/20" />
                  <div className="flex flex-col gap-2">
                    <span className="text-[10px] font-medium text-white/60">
                      Compliant with: SOC 2, CSA Star Level 1, NIST 800-218
                    </span>
                    <div className="flex w-full items-center justify-center gap-[25px]">
                      <img src={soc2Icon} alt="Soc2 logo" className="h-8" />
                      <img src={starIcon} alt="Start logo" className="h-8" />
                      <img src={nistIcon} alt="Nist logo" className="h-7" />
                    </div>
                  </div>
                </div>
              </Popover>
            </DialogTrigger> */}
            {settings.affinityLevel === 'none' && (
              <TooltipTrigger delay={0}>
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white/30 focus-visible:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 sm:min-w-[100px]"
                  type="button"
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
                    <div className="hidden text-[13px] font-medium sm:block">
                      {`${settings.cacheTtl}${
                        settings.cacheTtlUnit === 'seconds' ? 's' : settings.cacheTtlUnit === 'minutes' ? 'm' : 'd'
                      }`}{' '}
                      Cache TTL
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" />
                  </div>
                </Button>
                <Tooltip
                  offset={isNew ? 17 : 11}
                  placement="bottom"
                  className="flex flex-col overflow-y-auto rounded-md bg-[#151544] px-3 py-2 text-[14px] text-sm text-white/60 shadow-[3.0px_3.0px_5.0px_rgba(0,0,0,0.38)] focus:outline-none"
                >
                  Enable cache to change TTL
                </Tooltip>
              </TooltipTrigger>
            )}
            {settings.affinityLevel !== 'none' && (
              <DialogTrigger isOpen={isCacheTTLOpen} onOpenChange={setIsCacheTTLOpen}>
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] hover:text-white/80 focus-visible:bg-[#1D1D5E] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                  type="button"
                  onPress={() => setIsCacheTTLOpen(true)}
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faClock} className="h-3 w-3" />
                    <div className="hidden text-[13px] font-medium sm:block">
                      {`${formatNumberWithCommas(settings.cacheTtl)}${
                        settings.cacheTtlUnit === 'seconds' ? 's' : settings.cacheTtlUnit === 'minutes' ? 'm' : 'd'
                      }`}{' '}
                      Cache TTL
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" />
                  </div>
                </Button>
                <Popover
                  placement="bottom end"
                  offset={isNew ? 17 : 11}
                  className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 flex w-72 origin-top-left flex-col gap-2.5 overflow-auto rounded-md bg-[#151544] p-2.5 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
                >
                  <span className="text-[12px] font-medium text-white/60 ">Cache TTL expiration (max 2 days):</span>
                  <div className="flex items-center gap-2">
                    <NumberField
                      defaultValue={settings.cacheTtl}
                      value={currentCacheTtl}
                      minValue={0}
                      maxValue={currentCacheTtlUnit === 'days' ? 2 : currentCacheTtlUnit === 'minutes' ? 2880 : 172800}
                      className="h-8 w-16 rounded-md border-none bg-[#1D1D5E] px-1"
                      onChange={setCurrentCacheTtl}
                    >
                      <Group className="flex items-center justify-between">
                        <Input className="h-8 w-10 rounded-md border-none bg-[#1D1D5E] p-0 text-[12px] text-white outline-none focus-within:outline-none focus-within:ring-0 focus:outline-none focus:ring-0" />
                        <div className="flex flex-col">
                          <Button slot="increment">
                            <FontAwesomeIcon icon={faChevronUp} className="h-2.5 w-2.5 text-white" />
                          </Button>
                          <Button slot="decrement">
                            <FontAwesomeIcon icon={faChevronDown} className="h-2.5 w-2.5 text-white" />
                          </Button>
                        </div>
                      </Group>
                    </NumberField>
                    <MenuTrigger>
                      <Button
                        className="flex h-8 flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:text-white/40 focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50"
                        type="button"
                      >
                        <div className="flex w-full items-center justify-between gap-2">
                          <div className="text-[12px] font-medium capitalize text-white">{currentCacheTtlUnit}</div>
                          <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3 text-white" />
                        </div>
                      </Button>
                      <Popover
                        placement="bottom end"
                        offset={11}
                        crossOffset={8}
                        className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 w-60 origin-top-left overflow-auto rounded-md bg-[#1D1D5E] p-2 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
                      >
                        <Menu
                          className="bg-[#1D1D5E] outline-none"
                          aria-label="Sort menu"
                          selectionMode="single"
                          items={CacheUnits}
                          selectedKeys={[currentCacheTtlUnit]}
                          onAction={(key) => {
                            setCurrentCacheTtlUnit(key as CacheUnit);
                            setCurrentCacheTtl(
                              Math.min(currentCacheTtl, key === 'days' ? 2 : key === 'minutes' ? 2880 : 172800),
                            );
                          }}
                        >
                          {({ label, value }) => (
                            <MenuItem
                              id={value}
                              key={value}
                              className="group box-border flex w-full cursor-pointer items-center rounded-md px-2 py-2 text-white outline-none hover:bg-[#151544]"
                            >
                              {({ isSelected }) => (
                                <div className="flex w-full items-center justify-between text-[14px]">
                                  {label}
                                  {isSelected && <FontAwesomeIcon icon={faCheck} className="h-4 w-4 text-white" />}
                                </div>
                              )}
                            </MenuItem>
                          )}
                        </Menu>
                      </Popover>
                    </MenuTrigger>
                    <Button
                      className="h-8 min-w-12 rounded-md border-none bg-[#4000BF] text-[12px] text-white focus-within:bottom-0 focus-within:outline-none hover:bg-opacity-80 focus:ring-0 focus-visible:ring-0 disabled:bg-white/30"
                      type="button"
                      onPress={() => {
                        setIsCacheTTLOpen(false);
                        onChangeSettings({
                          ...settings,
                          cacheTtl: currentCacheTtl,
                          cacheTtlUnit: currentCacheTtlUnit as CacheUnit,
                        });
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Popover>
              </DialogTrigger>
            )}
            {/* {settings.affinityLevel === 'none' && (
              <TooltipTrigger delay={0}>
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white/30 hover:bg-[#202067] focus-visible:outline-none focus-visible:ring-0 sm:min-w-[100px]"
                  type="button"
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon="clock" className="h-3 w-3" />
                    <div className="hidden text-[12px] font-medium sm:block">
                      {`${settings.vectorTtl}${
                        settings.vectorTtlUnit === 'seconds' ? 's' : settings.vectorTtlUnit === 'minutes' ? 'm' : 'd'
                      }`}{' '}
                      Vector TTL
                    </div>
                    <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                  </div>
                </Button>
                <Tooltip
                  offset={isNew ? 17 : 11}
                  placement="bottom"
                  className="flex flex-col overflow-y-auto rounded-md bg-[#151544] px-3 py-2 text-[14px] text-sm text-white/60 shadow-[3.0px_3.0px_5.0px_rgba(0,0,0,0.38)] focus:outline-none"
                >
                  Enable cache to change TTL
                </Tooltip>
              </TooltipTrigger>
            )}
            {settings.affinityLevel !== 'none' && (
              <MenuTrigger>
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#202067] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                  type="button"
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon="clock" className="h-3 w-3" />
                    <div className="hidden text-[12px] font-medium sm:block">
                      {`${settings.vectorTtl}${
                        settings.vectorTtlUnit === 'seconds' ? 's' : settings.vectorTtlUnit === 'minutes' ? 'm' : 'd'
                      }`}{' '}
                      Vector TTL
                    </div>
                    <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                  </div>
                </Button>
                <Popover
                  placement="bottom end"
                  offset={isNew ? 17 : 11}
                  className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 flex w-64 origin-top-left flex-col gap-2.5 overflow-auto rounded-md bg-[#151544] p-2.5 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
                >
                  <span className="text-[12px] font-medium text-white/60 ">Your embeddings will expire in:</span>
                  <div className="flex items-center gap-2">
                    <NumberField
                      defaultValue={settings.vectorTtl}
                      minValue={0}
                      maxValue={settings.vectorTtlUnit === 'days' ? 365 : 60}
                      className="h-8 w-16 rounded-md border-none bg-[#1D1D5E] px-1"
                      onChange={(value) => {
                        onChangeSettings({
                          ...settings,
                          vectorTtl: value,
                        });
                      }}
                    >
                      <Group className="flex items-center justify-between">
                        <Input className="h-8 w-10 rounded-md border-none bg-[#1D1D5E] p-0 text-[12px] text-white outline-none focus-within:outline-none focus-within:ring-0 focus:outline-none focus:ring-0" />
                        <div className="flex flex-col">
                          <Button slot="increment">
                            <FontAwesomeIcon icon="chevron-up" className="h-2.5 w-2.5 text-white" />
                          </Button>
                          <Button slot="decrement">
                            <FontAwesomeIcon icon="chevron-down" className="h-2.5 w-2.5 text-white" />
                          </Button>
                        </div>
                      </Group>
                    </NumberField>
                    <MenuTrigger>
                      <Button
                        className="flex h-8 flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:text-white/40 focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50"
                        type="button"
                      >
                        <div className="flex w-full items-center justify-between gap-2">
                          <div className="text-[12px] font-medium capitalize">{settings.vectorTtlUnit}</div>
                          <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                        </div>
                      </Button>
                      <Popover
                        placement="bottom end"
                        offset={11}
                        crossOffset={8}
                        className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 w-44 origin-top-left overflow-auto rounded-md bg-[#1D1D5E] p-2 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
                      >
                        <Menu
                          className="bg-[#1D1D5E] outline-none"
                          aria-label="Sort menu"
                          selectionMode="single"
                          items={CacheUnits}
                          selectedKeys={[settings.vectorTtlUnit]}
                          onAction={(key) => {
                            onChangeSettings({
                              ...settings,
                              vectorTtlUnit: key as CacheUnit,
                            });
                          }}
                        >
                          {({ label, value }) => (
                            <MenuItem
                              id={value}
                              key={value}
                              className="group box-border flex w-full cursor-default items-center rounded-md px-2 py-2 text-white outline-none hover:bg-[#151544]"
                            >
                              {({ isSelected }) => (
                                <div className="flex w-full items-center justify-between text-[14px]">
                                  {label}
                                  {isSelected && <FontAwesomeIcon icon="check" className="h-4 w-4" />}
                                </div>
                              )}
                            </MenuItem>
                          )}
                        </Menu>
                      </Popover>
                    </MenuTrigger>
                  </div>
                </Popover>
              </MenuTrigger>
            )}
            {settings.affinityLevel === 'none' && (
              <TooltipTrigger delay={0}>
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white/30 hover:bg-[#202067] hover:bg-opacity-70 focus-visible:bg-[#1D1D5E] focus-visible:outline-none focus-visible:ring-0 sm:min-w-[100px]"
                  type="button"
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon="gear" className="h-3 w-3" />
                    <div className="hidden text-[12px] font-medium sm:block">
                      {settings.cacheControl ? 'Cache control' : 'No cache control'}
                    </div>
                    <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                  </div>
                </Button>
                <Tooltip
                  offset={isNew ? 17 : 11}
                  placement="bottom"
                  className={twMerge(
                    'flex flex-col overflow-y-auto rounded-md bg-[#151544] px-3 py-2 text-[14px] text-sm text-white/60 shadow-[3.0px_3.0px_5.0px_rgba(0,0,0,0.38)] focus:outline-none',
                    '',
                  )}
                >
                  Enable cache to change cache control
                </Tooltip>
              </TooltipTrigger>
            )}
            {settings.affinityLevel !== 'none' && (
              <MenuTrigger>
                <Button
                  className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#202067] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                  type="button"
                >
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon="gear" className="h-3 w-3" />
                    <div className="hidden text-[12px] font-medium sm:block">
                      {settings.cacheControl ? 'Cache control' : 'No cache control'}
                    </div>
                    <FontAwesomeIcon icon="chevron-down" className="h-3 w-3" />
                  </div>
                </Button>
                <Popover
                  placement="bottom end"
                  offset={isNew ? 17 : 11}
                  className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 flex w-64 origin-top-left flex-col gap-2.5 overflow-auto rounded-md bg-[#151544] p-2.5 shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards"
                >
                  <span className="text-[12px] font-medium text-white/60">
                    Support HTTP Cache Control as defined in RFC 7234
                  </span>
                  <div className="flex items-center gap-2">
                    <Switch
                      className="group flex cursor-pointer items-center gap-2 text-[12px] font-semibold text-white"
                      isSelected={settings.cacheControl}
                      onChange={(value) => {
                        onChangeSettings({
                          ...settings,
                          cacheControl: value,
                        });
                      }}
                    >
                      <div className="box-border flex h-[26px] w-[44px] shrink-0 cursor-pointer overflow-hidden rounded-lg border border-solid border-white/20 bg-white/40 bg-clip-padding p-[3px] shadow-inner outline-none ring-black transition duration-200 ease-in-out group-focus-visible:ring-2 group-data-[pressed]:bg-[#292984] group-data-[selected]:bg-[#292984] group-data-[selected]:group-data-[pressed]:bg-[#292984]">
                        <span className="h-[18px] w-[18px] translate-x-0 transform rounded-lg bg-white shadow transition duration-200 ease-in-out before:absolute before:-right-4 before:top-1 before:h-3 before:w-3 before:rounded-[5px] before:border-2 before:border-white/50 before:content-[''] after:opacity-0 after:transition-all after:duration-0 after:ease-linear group-data-[selected]:translate-x-[100%] group-data-[selected]:after:absolute group-data-[selected]:after:-left-3 group-data-[selected]:after:top-1 group-data-[selected]:after:h-3  group-data-[selected]:after:w-1 group-data-[selected]:after:rounded-lg group-data-[selected]:after:bg-white/70 group-data-[selected]:after:text-white group-data-[selected]:after:opacity-100 group-data-[selected]:after:content-['']" />
                      </div>
                      {settings.cacheControl ? 'Enabled' : 'Disabled'}
                    </Switch>
                  </div>
                </Popover>
              </MenuTrigger>
            )} */}
            <DialogTrigger>
              <Button
                className="flex h-8 w-fit min-w-[unset] flex-1 shrink-0 select-none items-center justify-center gap-1 whitespace-nowrap rounded-md bg-[#1D1D5E] px-2 py-1 text-sm font-medium text-white transition-colors hover:bg-[#272777] focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:text-white/30 sm:min-w-[100px]"
                type="button"
              >
                <div className="flex items-center gap-2">
                  {guardrailsCount > 0 ? <GuardrailsIcon /> : <NoGuardrailsIcon />}
                  <div className="hidden text-[13px] font-medium sm:block">
                    {guardrailsCount > 0
                      ? `${guardrailsCount} ${guardrailsCount > 1 ? 'guardrails' : 'guardrail'}`
                      : 'No guardrails'}
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} className="h-3 w-3" />
                </div>
              </Button>
              <GuardrailsModal
                guardrails={(Object.keys(guardrails) as Guardrails[]).filter((key) => guardrails[key])}
                onSelect={(guardrails: Guardrails[]) => {
                  onChangeSettings({
                    ...settings,
                    ...createGuardrailsObject(guardrails),
                  });
                }}
              />
            </DialogTrigger>
          </div>
        </div>
      </div>
    </div>
  );
}
